import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import { USER_BLOCK, USER_DETAIL } from "../..//queries/user";
import { Button } from "../../components/Button";

const BlockUser = ({ id, attempt }) => {
  const refetchQueryOptions = [
    {
      query: USER_DETAIL,
      variables: { id },
    },
  ];

  /**
   * @TODO
   * Callbacks unnecessary,
   * but console ideal place for the notifications.
   */

  const clickHander = (fn, block) =>
    fn({ variables: { id, block } })
      .then((resp) => console.log(resp))
      .catch((err) => console.log(err));

  if (!id) return null;

  return (
    <Mutation mutation={USER_BLOCK} refetchQueries={refetchQueryOptions}>
      {(call, { data }) =>
        /**
         * @NOTE
         * Users cannot login if attempt exceeds 10.
         * Here, we can check for blocked users.
         * Unblocking will set incrementer to 0.
         */

        attempt < 10 ? (
          <Button
            secondary
            type="button"
            onClick={() => clickHander(call, true)}
          >
            {"Block"}
          </Button>
        ) : (
          <Button
            secondary
            type="button"
            onClick={() => clickHander(call, false)}
          >
            {"Unblock"}
          </Button>
        )
      }
    </Mutation>
  );
};

BlockUser.propTypes = {
  id: PropTypes.string.isRequired,
  attempt: PropTypes.number,
};

BlockUser.defaultProps = {
  attempt: 0,
};

export default BlockUser;
