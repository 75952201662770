import React from "react";
import { Query } from "react-apollo";
import { USER_LIST_DROPDOWN } from "../..//queries/user";
import Group from "../../components/Group";
import { isFunction, get, map } from "lodash";
import ExpandedField from "../../components/ExpandedField";
import RateTypeField from "../../components/RateTypeField";
import BillingCycleField from "../../components/BillingCycleField";
import LocationField from "../../components/LocationField";
import { ProtectedElement } from "../../components/Protected";

export const UserInvoiceDropdown = ({
  // eslint-disable-next-line
  children,
  // eslint-disable-next-line
  required,
  // eslint-disable-next-line
  disabled,
  ...props
}) => (
  <Query
    query={USER_LIST_DROPDOWN}
    fetchPolicy="network-only"
    variables={{ ...props, type: "manager" }}
  >
    {({ loading, data }) => (
      <>
        <ExpandedField
          required={required}
          label="Flextime Manager"
          name="manager"
          disabled={loading || disabled}
        >
          <option value="">{loading ? "Loading..." : "Select"}</option>
          {map(get(data, "getUsersForSelect", []), (obj, i) => (
            <option key={i} value={obj._id.toString()}>
              {obj.firstName} {obj.lastName}
            </option>
          ))}
        </ExpandedField>
        {isFunction(children) ? children(data) : children}
      </>
    )}
  </Query>
);

export const Quickbooks = (props) => (
  <ExpandedField
    {...props}
    label="QuickBooks URL"
    helper="Without this, the system cannot auto-generate Expenses"
    name="url"
  />
);

// eslint-disable-next-line
const UserInvoice = ({ children }) => (
  <Group>
    <h2>Invoicing details</h2>
    <ExpandedField label="Contract #" name="contract" required />
    <ExpandedField label="Flexstaf Account Manager" name="accountManager" />
    <UserInvoiceDropdown required />
    <ExpandedField
      label="Start date (from first contract)"
      name="originalStartDate"
      type="date"
      helper="This value does not change after a contract extension/amendment. It's used to populate start-end ranges in the contractor CSV export."
    />
    <ExpandedField label="Start date" name="startDate" type="date" />
    <ExpandedField label="End date" name="endDate" type="date" />
    <ExpandedField label="Position/Title" name="role" />
    <LocationField />
    <RateTypeField required={false} />
    <ExpandedField required label="Candidate rate" name="rate" type="price" />
    <ExpandedField
      required
      label="Billing rate"
      name="billableRate"
      type="price"
    />
    <ProtectedElement rule="admin">
      <ExpandedField
        disabled
        helper="This field has been disabled because its value is auto-calculated"
        label="Gross margin"
        name="gm"
        type="price"
      />
      <ExpandedField
        disabled
        helper="This field has been disabled because its value is auto-calculated"
        label="Net margin"
        name="nm"
        type="price"
      />
    </ProtectedElement>
    <BillingCycleField />
    <ExpandedField
      label="Preset Hours in a Day"
      name="hoursInDay"
      type="number"
    />
    <ExpandedField label="PO" name="po" />
    {children}
  </Group>
);

export default UserInvoice;
