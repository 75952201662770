import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { ErrorMessage, useField } from "formik";
import { get, find, isObject } from "lodash";
import { USER_LIST_DROPDOWN } from "../../queries/user";
import styled from "styled-components";
import { compact } from "lodash";
import withFieldRepeater from "../../components/withFieldRepeater";
import Multiselect from "../../components/Multiselect";
import ExpandedField from "../../components/ExpandedField";

export const Styled = styled.div`
  width: 100%;

  input {
    background: #fff !important;
  }

  label {
    width: 100%;
  }

  .optionContainer li:hover,
  .optionContainer .highlight {
    background: #e52225;
    color: #fff;
  }
`;

const map = (xs, fn) => compact(Array.isArray(xs) ? xs : [xs]).map(fn);
const findByValue = (a, b) => find(a, (op) => get(op, "value") === b);

const mergeStateWithSelectOptions = (state, options) =>
  compact(
    map(state, (item) => (isObject(item) ? item : findByValue(options, item)))
  );

export const MultiSelectField = ({ options, name }) => {
  const [field, , helpers] = useField(name);
  const { value } = field;

  return (
    <Multiselect
      displayValue="label"
      style={{
        chips: { background: "#E52225" },
      }}
      selectedValues={mergeStateWithSelectOptions(value, options)}
      avoidHighlightFirstOption
      onRemove={helpers.setValue}
      onSelect={helpers.setValue}
      options={options}
    />
  );
};

MultiSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

// eslint-disable-next-line
export const withUserDropdown = (Component) => ({ label, name, ...props }) => (
  <Query
    query={USER_LIST_DROPDOWN}
    variables={{ ...props, contractorsOnly: true }}
    fetchPolicy="network-only"
  >
    {({ data }) => (
      <Component
        label={label}
        name={name}
        options={get(data, "getUsersForSelect", []).map((obj) => ({
          label: `${obj.firstName} ${obj.lastName}`,
          value: obj._id,
        }))}
      />
    )}
  </Query>
);

const UserDropdownMulti = withUserDropdown(({ label, options, name }) => (
  <Styled>
    <label>
      {label}
      <MultiSelectField required name={name} options={options} />
      <ErrorMessage component="span" name={name} />
    </label>
  </Styled>
));

const Po = ({ id, idx }) => (
  <>
    <ExpandedField label="PO" required name={`pos[${idx}].value`} />
    <UserDropdownMulti
      label="Select the workers who apply during invoicing: *"
      name={`pos[${idx}].worker`}
      manager={id}
    />
  </>
);

Po.propTypes = {
  id: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
};

export default withFieldRepeater(
  Po,
  { title: "POs", key: "pos" },
  {
    worker: "",
    value: "",
  }
);
