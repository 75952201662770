import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import Group from "../../components/Group";
import { get } from "lodash";
import { COMPANY_LIST } from "../../queries/company";
import ExpandedField from "../../components/ExpandedField";

export const UserCompanyList = (props) => (
  <Query query={COMPANY_LIST} variables={{ limit: 100 }}>
    {({ loading, data }) => (
      <ExpandedField
        label="Company"
        name="company"
        disabled={loading}
        {...props}
      >
        <option value="">{loading ? "Loading..." : "Select"}</option>
        {get(data, "getCompanies", []).map((obj, i) => (
          <option key={i} value={obj._id}>
            {obj.name}
          </option>
        ))}
      </ExpandedField>
    )}
  </Query>
);

UserCompanyList.propTypes = {
  required: PropTypes.bool,
};

UserCompanyList.defaultProps = {
  required: false,
};

const UserCompany = () => (
  <Group>
    <h2>Company details</h2>
    <ExpandedField label="Title" name="title" />
    <UserCompanyList required />
  </Group>
);

export default UserCompany;
