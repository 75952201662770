import React from "react";
import PropTypes from "prop-types";
import Group from "../../components/Group";
import ExpandedField from "../../components/ExpandedField";
import CandidateSource from "../../components/CandidateSourceField";

const UserContact = ({ showIsPortalUser, showPassword }) => (
  <Group>
    <h2>Contact information</h2>
    <ExpandedField name="firstName" label="First name" required />
    <ExpandedField name="lastName" label="Last name" required />
    <ExpandedField name="email" label="Email" type="email" required />
    <ExpandedField name="phone" label="Phone" type="tel" required />
    {showPassword && (
      <ExpandedField
        name="password"
        label="Password"
        helper="Users can change this from inside the portal"
        required
      />
    )}
    {showIsPortalUser && (
      <ExpandedField
        name="isPortal"
        label=" Is portal user?"
        helper="Non-portal users cannot login and will not receive email notifications"
        required
      >
        <option value="">Select</option>
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </ExpandedField>
    )}
    {["employee", "payroll", "subcontractor"].map((type) => (
      <CandidateSource key={type} conditional={{ type }} />
    ))}
    <ExpandedField
      name="active"
      label="Status"
      helper="Inactive users cannot access the portal and will be filtered from some dropdowns"
      required
    >
      <option value="">Select</option>
      <option value={true}>Active</option>
      <option value={false}>Inactive</option>
    </ExpandedField>
  </Group>
);

UserContact.defaultProps = {
  showIsPortalUser: true,
  showPassword: true,
};

UserContact.propTypes = {
  showIsPortalUser: PropTypes.bool,
  showPassword: PropTypes.bool,
};

export default UserContact;
