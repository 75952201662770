import React from "react";
import { useFormikContext } from "formik";
import DetailPageErrors from "./DetailPageErrors";

const DetailPageErrorsConsumer = () => {
  const { setErrors } = useFormikContext();
  const errors = React.useContext(DetailPageErrors);

  React.useEffect(() => {
    // setTouched(
    //   Object.keys(errors).reduce((acc, curr) => {
    //     acc[curr] = true;
    //     return acc;
    //   }, {})
    // );
    setErrors(errors);
  }, [errors]);

  return null;
};

export default DetailPageErrorsConsumer;
