import React from "react";
import { Query } from "react-apollo";
import { get } from "lodash";
import { USER_LIST_DROPDOWN } from "../queries/user";

const withUserDropdown = (
  Component,
  { recruitersOnly = false, contractorsOnly = false }
) => {
  const DecoratedComponent = (props) => (
    <Query
      fetchPolicy="network-only"
      query={USER_LIST_DROPDOWN}
      variables={{
        recruitersOnly,
        contractorsOnly,
      }}
    >
      {({ loading, data }) =>
        !loading ? (
          <Component {...props} users={get(data, "getUsersForSelect", [])} />
        ) : (
          "Loading data..."
        )
      }
    </Query>
  );

  return DecoratedComponent;
};

export default withUserDropdown;
