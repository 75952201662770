import React from "react";
import PropTypes from "prop-types";
import withFieldRepeater from "../../components/withFieldRepeater";
import ExpandedField from "../../components/ExpandedField";
import Group from "../../components/Group";

const InvoicingContact = ({ idx }) => (
  <Group>
    <ExpandedField label="Name" name={`invoicingContacts[${idx}].name`} />
    <ExpandedField label="Title" name={`invoicingContacts[${idx}].title`} />
    <ExpandedField
      label="Email"
      name={`invoicingContacts[${idx}].email`}
      type="email"
    />
    <ExpandedField
      label="Phone"
      name={`invoicingContacts[${idx}].phone`}
      type="tel"
    />
  </Group>
);

InvoicingContact.propTypes = {
  idx: PropTypes.number.isRequired,
};

export default withFieldRepeater(
  InvoicingContact,
  { title: "Client Invoicing Contacts", key: "invoicingContacts" },
  {
    name: "",
    email: "",
    phone: "",
    title: "",
  }
);
