import React from "react";
import ExpandedField from "./ExpandedField";

const LocationField = () => (
  <ExpandedField label="Location" name="location">
    <option value="">Select</option>
    <option value="On-site">On-site</option>
    <option value="Remote">Remote</option>
    <option value="Hybrid">Hybrid</option>
  </ExpandedField>
);

export default LocationField;
