import React from "react";
import PropTypes from "prop-types";
import Group from "./Group";
import ExpandedField from "./ExpandedField";
import AddressFields from "./AddressFields";
import { useField } from "formik";

const ContractorBusinessFields = ({ showTitle }) => {
  const [{ value }] = useField("isIncorporated");
  const typesOfBusinesses = [
    "Incorporated",
    "Partnership",
    "Sole Proprietorship",
  ];

  return (
    <>
      {showTitle && (
        <>
          <h2>Business Information</h2>
          <p style={{ marginTop: "-1.5rem", marginBottom: "2rem" }}>
            Include a business address (if shown) only when different than above
          </p>
        </>
      )}
      <Group>
        <ExpandedField label="Business Type" name="isIncorporated">
          <option value="">Select</option>
          {typesOfBusinesses.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </ExpandedField>
        {value && (
          <>
            <ExpandedField required label="Business name" name="businessName" />
            <AddressFields required={false} prefix="registeredAddress" />
          </>
        )}
        <ExpandedField label="Has an HST number?" name="isHst">
          <option value>Select</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </ExpandedField>
        <ExpandedField
          required
          conditional={{ isHst: "Yes" }}
          label="HST number"
          name="hst"
        />
        <ExpandedField
          label="Tax (%)"
          name="tax"
          type="number"
          helper="Used to generate tax on approved timesheets"
        />
      </Group>
    </>
  );
};

ContractorBusinessFields.defaultProps = {
  showTitle: true,
};

ContractorBusinessFields.propTypes = {
  showTitle: PropTypes.bool,
};

export default ContractorBusinessFields;
