/* eslint-disable complexity,react/display-name,react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import { pick, isEqual, isObject, isString } from "lodash";
import MaskedInput from "react-input-mask";
import CurrencyInput from "react-currency-input-field";

const MASKS = {
  tel: "(999) 999-9999",
  // eslint-disable-next-line
  postal_ca: "a9a 9a9",
  // eslint-disable-next-line
  postal_us: "99999",
};

const capitalizeSelectively = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const wrapWith = (str, char, fn) =>
  isString(str) ? str.split(char).map(fn).join(char) : "";

const handleSpecialCharacters = (chars) => (item) =>
  chars.reduce((acc, curr) => wrapWith(acc, curr, capitalizeSelectively), item);

export const capitalizeSentence = (str) =>
  wrapWith(str, " ", handleSpecialCharacters(["-", "/", "("]));

export const displayRequiredFlag = (str, isRequired) =>
  isRequired ? `${str} *` : str;

const ExpandedFieldCurrency = ({
  field: { onChange, ...field },
  disabled,
  required,
}) => {
  const init = field.value ? Number(field.value).toFixed(2) : 0;
  const [state, setState] = React.useState(init);

  React.useEffect(() => {
    if (disabled) setState(init);
  }, [disabled, init]);

  React.useEffect(() => {
    if (!init && state) setState(init);
  }, [state, init]);

  return (
    <CurrencyInput
      {...field}
      disabled={disabled}
      required={required}
      value={state}
      onValueChange={(value, name, { float }) => {
        if (String(value) === "0.") {
          float = undefined;
          value = null;
        }

        setState(value);
        onChange({
          target: {
            value: float,
            name,
          },
        });
      }}
      prefix="$"
      // decimalsLimit={2}
      step={1}
    />
  );
};

const ExpandedField = ({
  children,
  label,
  helper,
  name,
  conditional,
  type,
  emphasize,
  ...props
}) => {
  const ct = useFormikContext();
  // eslint-disable-next-line
  const error = ct.errors[name];
  const { required, disabled } = props;

  const getRenderer = () => {
    if (type === "price")
      return (f) =>
        React.createElement(ExpandedFieldCurrency, {
          ...f,
          disabled,
          required,
        });

    if (Object.keys(MASKS).includes(type))
      return ({ field }) => (
        <MaskedInput
          {...field}
          // eslint-disable-next-line
          mask={MASKS[type]}
          type="text"
        />
      );

    return undefined;
  };

  if (
    isObject(conditional) &&
    !isEqual(conditional, pick(ct.values, Object.keys(conditional)))
  )
    return null;

  return (
    <label>
      {displayRequiredFlag(capitalizeSentence(label), required)}
      {children ? (
        <Field name={name} component="select" {...props}>
          {children}
        </Field>
      ) : (
        <Field name={name} type={type} {...props} render={getRenderer()} />
      )}
      {helper && (
        <small
          style={
            emphasize
              ? {
                  fontWeight: "bold",
                  color: "red",
                }
              : {}
          }
        >
          {helper}
        </small>
      )}
      {error && <span>{error}</span>}
    </label>
  );
};

ExpandedField.defaultProps = {
  children: null,
  type: "text",
  required: false,
  conditional: null,
};

ExpandedField.propTypes = {
  conditional: PropTypes.object,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  helper: PropTypes.string,
};

export default ExpandedField;
