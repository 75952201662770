import styled from 'styled-components';
import vars from '../lib/styled';

export default styled.button.attrs({
    type: 'button'
})`
    background: transparent;
    border: 0;
    color: ${vars.danger};
    display: inline-block;
    font-weight: bold;
    font-size: 0.833rem;
    text-decoration: underline;
`;