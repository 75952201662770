import React from "react";
import ExpandedField from "./ExpandedField";
import { compact } from "lodash";
import { useField } from "formik";

export const CA = "CA";
export const US = "US";

export const PROVINCES = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NT",
  "NS",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
];

export const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

// eslint-disable-next-line
const Address = ({ prefix, required = true }) => {
  const makeName = (xs) => compact([prefix, xs]).join(".");

  const COUNTRY_NAME = makeName("country");
  const POSTAL_NAME = makeName("postal");
  const PROVINCE_NAME = makeName("province");
  const [{ value }] = useField(COUNTRY_NAME);

  const getOptions = () => {
    if (value === CA) return PROVINCES;
    if (value === US) return STATES;
    return [];
  };

  const getType = () => {
    if (!value) return "";
    return `postal_${String(value).toLowerCase()}`;
  };

  return (
    <>
      <ExpandedField
        label="Street name"
        name={makeName("street")}
        required={required}
      />
      <ExpandedField label="City" name={makeName("city")} required={required} />
      <ExpandedField
        label="Province"
        name={PROVINCE_NAME}
        required={required}
        disabled={!value}
      >
        <option value>Select</option>
        {getOptions().map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </ExpandedField>
      <ExpandedField
        label="Country"
        name={COUNTRY_NAME}
        required={required}
        helper="Must make a selection to enter province/state and postal code"
      >
        <option value>Select</option>
        <option value={CA}>Canada</option>
        <option value={US}>United States</option>
      </ExpandedField>
      <ExpandedField
        label="Postal"
        country={value}
        name={POSTAL_NAME}
        type={getType()}
        required={required}
        disabled={!value}
      />
    </>
  );
};

export default Address;
