import React from "react";
import ExpandedField from "./ExpandedField";

const RateTypeField = (props) => (
  <ExpandedField label="Rate type" name="rateType" required {...props}>
    <option value="">Select</option>
    <option value="Daily">Daily</option>
    <option value="Hourly">Hourly</option>
  </ExpandedField>
);

export default RateTypeField;
