import { find, get, map } from "lodash";
import moment from "moment";

export const getFirstNameByUserId = (a, id) =>
  get(
    find(a, (item) => item._id === id),
    "firstName"
  );

export const toDate = (xs) =>
  xs && moment(xs).isValid() ? moment(xs).utc().format("YYYY-MM-DD") : "";

export const mapExpiryDate = (xs) =>
  map(xs, (item) => ({
    ...item,
    expiryDate: toDate(item.expiryDate),
  }));
