import styled, { css } from 'styled-components';
import withMedia from './withMedia';

export default styled.div.attrs({
    role: 'group'
})`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    legend,
    h2 {
        width: 100%;
        flex-basis: 100%;
    }

    label {
        width: 49%;
        flex-basis: 49%;
        ${withMedia('tablet', css`
            flex-basis: 100%;
            width: 100%;
        `)}
    }
`;