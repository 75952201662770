import React from "react";
import ExpandedField from "./ExpandedField";

const CandidateSourceField = (props) => (
  <ExpandedField label="Candidate source" name="candidateSource" {...props}>
    <option value="">Select</option>
    <option value="Job Posting">Job Posting</option>
    <option value="LinkedIn">LinkedIn</option>
    <option value="Indeed">Indeed</option>
    <option value="Monster">Monster</option>
    <option value="Referral">Referral</option>
    <option value="Other">Other</option>
  </ExpandedField>
);
export default CandidateSourceField;
