import gql from "graphql-tag";

export const COMPANY_LIST = gql`
  query getCompanies($search: String, $skip: Float, $limit: Float) {
    getCompanies(search: $search, skip: $skip, limit: $limit) {
      _id
      name
    }
  }
`;

export const COMPANY_DETAIL = gql`
  query getCompanyDetail($id: ID!) {
    getCompanyDetail(id: $id) {
      _id
      url
      name
      street
      city
      province
      postal
      street
      city
      province
      postal
      country
      billingCycle
    }
  }
`;

export const COMPANY_CREATE = gql`
  mutation createCompany(
    $name: String!
    $url: String!
    $street: String
    $city: String
    $province: String
    $postal: String
    $country: String
    $billingCycle: String
  ) {
    createCompany(
      name: $name
      url: $url
      street: $street
      city: $city
      province: $province
      postal: $postal
      country: $country
      billingCycle: $billingCycle
    ) {
      insertedId
    }
  }
`;

export const COMPANY_UPDATE = gql`
  mutation updateCompany(
    $id: ID!
    $name: String!
    $url: String!
    $street: String
    $city: String
    $province: String
    $postal: String
    $country: String
    $billingCycle: String
  ) {
    updateCompany(
      id: $id
      name: $name
      url: $url
      street: $street
      city: $city
      province: $province
      postal: $postal
      country: $country
      billingCycle: $billingCycle
    ) {
      name
      url
      street
      city
      province
      postal
      country
      billingCycle
    }
  }
`;

export const COMPANY_DELETE = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      active
    }
  }
`;
