/* eslint-disable security/detect-object-injection  */
import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { get, map as arrayMap } from "lodash";
import Submit from "../../components/Submit";
import UserContactInformation from "./contact";
import ManagerCompanyInformation from "./company";
import Address from "./address";
import Invoice, { Quickbooks } from "./invoice";
import Group from "../../components/Group";
import BlockUser from "./block";
import Commission from "./commission";
import Po from "./po";
import { toDate } from "../utils";
import ExpandedField from "../../components/ExpandedField";
import DetailPageErrorsConsumer from "../../components/DetailPageErrorsConsumer";
import InvoicingContacts from "./invoicingContacts";
import ContractorBusinessFields from "../../components/ContractorBusinessFields";

const isTruthy = (xs) => String(xs) === "true";

const UserForm = ({ query, role, init }) => {
  let map = {
    administrator: [AdminForm, AdminFieldMap],
    moderator: [AdminForm, AdminFieldMap],
    manager: [ManagerForm, ManagerFieldMap],
    employee: [EmployeeForm, EmployeeFieldMap],
    recruiter: [RecruiterForm, RecruiterFormMap],
    subcontractor: [SubContractorForm, SubContractorFieldMap],
    payroll: [PayrollForm, PayrollFieldMap],
  };

  if (!map[role]) return null;

  let Comp = map[role][0];
  let fields = map[role][1];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        type: role,
        ...(init ? sanitizeInitData(init) : fields),
      }}
      validationSchema={UserValidation}
      onSubmit={(payload, actions) => {
        let variables = {};

        variables[role] = payload.type
          ? matchObject(payload, roleMap[payload.type])
          : payload;

        variables[role].active = isTruthy(payload.active);

        if (payload.type !== "payroll")
          variables[role].isPortal = isTruthy(payload.isPortal);

        if (Array.isArray(variables[role].pos))
          variables[role].pos = arrayMap(variables[role].pos, (item) => ({
            ...item,
            worker: arrayMap(item.worker, (xs) =>
              typeof xs === "object" ? xs.value : xs
            ),
          }));

        if (init) variables.id = init._id;
        return query(variables).then(() => {
          actions.resetForm();
          return true;
        });
      }}
    >
      {(values) => (
        <Form>
          <Comp {...values} id={get(init, "_id")} />
          <BlockUser {...init} id={init ? init._id : null} />
          <Submit text="Save" />
          <DetailPageErrorsConsumer />
        </Form>
      )}
    </Formik>
  );
};

UserForm.propTypes = {
  query: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  init: PropTypes.object,
};

export default UserForm;

export const AdminForm = () => <UserContactInformation />;

export const RecruiterForm = (props) => (
  <>
    <UserContactInformation />
    <Commission {...props} />
  </>
);

export const ManagerForm = (props) => (
  <>
    <UserContactInformation />
    <ManagerCompanyInformation />
    <Po {...props} />
  </>
);

export const PayrollForm = (props) => (
  <>
    <UserContactInformation showIsPortalUser={false} showPassword={false} />
    <Address />
    <Invoice />
    <InvoicingContacts {...props} />
  </>
);

export const EmployeeForm = (props) => (
  <>
    <UserContactInformation />
    <Address />
    <Invoice />
    <h2>Employee Details</h2>
    <Group>
      <ExpandedField
        required
        label="Date of Birth"
        name="birthday"
        type="date"
      />
    </Group>
    <InvoicingContacts {...props} />
  </>
);

export const SubContractorForm = (props) => (
  <>
    <UserContactInformation />
    <Address />
    <Invoice>
      <Quickbooks />
    </Invoice>
    <h2>Contractor Details</h2>
    <ContractorBusinessFields showTitle={false} />
    <InvoicingContacts {...props} />
  </>
);

export const sanitizeInitData = (data) => {
  let copy = { ...data };
  delete copy._id;
  delete copy.__typename;

  copy.active = String(copy.active);
  copy.isPortal = String(copy.isPortal);

  Object.entries(copy).forEach((entry) => {
    if (!entry[1] && entry[1] !== 0) copy[entry[0]] = "";
    else if (
      ["birthday", "startDate", "endDate", "originalStartDate"].includes(
        entry[0]
      )
    )
      copy[entry[0]] = toDate(entry[1]);
  });

  return copy;
};

const AdminFieldMap = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  active: true,
  isPortal: true,
};

const RecruiterFormMap = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  commissions: [
    {
      worker: "",
      rate: "",
      showFsNet: false,
    },
  ],
  active: true,
};

const ManagerFieldMap = {
  ...AdminFieldMap,
  title: "",
  company: "",
  po: "",
  pos: [
    {
      worker: "",
      value: "",
    },
  ],
};

const PayrollFieldMap = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  active: true,
  accountManager: "",
  street: "",
  city: "",
  province: "",
  country: "",
  postal: "",
  manager: "",
  contract: "",
  rate: 0,
  rateType: "",
  billableRate: 0,
  hoursInDay: 0,
  url: "",
  role: "",
  startDate: "",
  endDate: "",
  originalStartDate: "",
  billingCycle: "",
  notes: "",
  candidateSource: "",
  gm: 0,
  nm: 0,
  location: "",
  po: "",
  invoicingContacts: [
    {
      name: "",
      email: "",
      phone: false,
    },
  ],
};

const EmployeeFieldMap = {
  ...AdminFieldMap,
  ...PayrollFieldMap,
  birthday: "",
  td1one: "",
  td1e: "",
};

const SubContractorFieldMap = {
  ...EmployeeFieldMap,
  hst: "",
  tax: 13,
  isIncorporated: "",
  businessName: "",
  registeredAddress: {},
  isHst: "",
};

const UserValidation = yup.object().shape({
  email: yup.string().email().nullable(),

  phone: yup
    .string()
    .matches(
      /^(1)?(\()?\d{3}(\))?(\s)?(-)?\d{3}(-)?\d{4}$/,
      "Not a valid phone number"
    )
    .nullable(),

  // postal: yup
  //   .string()
  //   .matches(
  //     /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
  //     "Not a valid postal code"
  //   ),

  tax: yup.number().max(100),

  hoursInDay: yup
    .number()
    .when("rateType", (rateType, schema) =>
      rateType === "Daily"
        ? schema
            .min(7, "Cannot be less than 7 hours")
            .max(9, "Cannot be greater than 9 hours")
            .required("This is a required field")
        : schema.min(0)
    ),
});

const matchObject = (obj, map) => {
  let resp = {};
  Object.entries(map).forEach((entry) => {
    let key = entry[0];
    let value = entry[1];
    resp[key] = obj[key] !== "" ? obj[key] : value;
  });

  return resp;
};

const roleMap = {
  administrator: AdminFieldMap,
  moderator: AdminFieldMap,
  manager: ManagerFieldMap,
  payroll: PayrollFieldMap,
  subcontractor: SubContractorFieldMap,
  employee: EmployeeFieldMap,
  recruiter: RecruiterFormMap,
};

export const stripEmptyStrings = (obj) => {
  let resp = {};
  Object.entries(obj).forEach((entry) => {
    if (entry[1] || entry[1] === 0) {
      resp[entry[0]] = entry[1];
    }
  });
  return resp;
};
