import React, { Suspense, lazy } from "react";

const resolve = (resolver, name = "default") =>
  lazy(async () => {
    const resolved = await resolver();
    return { default: resolved[name] };
  });

// eslint-disable-next-line
const El = resolve(() => import("multiselect-react-dropdown"), "Multiselect");

export default (props) => (
  <Suspense fallback={<div />}>
    <El {...props} />
  </Suspense>
);
