import React from "react";
import ExpandedField from "./ExpandedField";

const BillingCycleField = (props) => (
  <ExpandedField label="Billing cycle" name="billingCycle" {...props}>
    <option value="">Select</option>
    <option value="Weekly">Weekly</option>
    <option value="Bi-Weekly">Bi-Weekly</option>
    <option value="Monthly">Monthly</option>
    <option value="Bi-Monthly (15th & last day of month)">
      Bi-Monthly (15th & last day of month)
    </option>
    <option value="Other">Other</option>
  </ExpandedField>
);
export default BillingCycleField;
