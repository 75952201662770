import React from "react";
import Group from "../../components/Group";
import AddressFields from "../../components/AddressFields";

const UserAddress = () => (
  <Group>
    <h2>Address</h2>
    <AddressFields />
  </Group>
);

export default UserAddress;
