/* eslint-disable react/prop-types,react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { get, map, size, sortBy, isFunction } from "lodash";
import Operator from "./Operator";

const Auto = ({ children }) => (
  <div style={{ margin: "1.5rem .5rem", width: "auto" }}>{children}</div>
);

const Stretch = ({ children }) => <div style={{ flex: 1 }}>{children}</div>;

const Wrap = ({ isFirst, children }) => (
  <div
    style={{
      display: "flex",
      ...(!isFirst
        ? {
            borderTop: "1px solid #fff",
            padding: "2rem 0 1rem",
          }
        : {
            paddingBottom: "1rem",
          }),
    }}
  >
    {children}
  </div>
);

const isFirst = (a, index) => a.length && index === 0;
const isLast = (a, index) => a.length - 1 === index;

const makeIncrementorElements = (helpers, initialValues = {}) => ({
  Add: (props) => (
    <div style={props}>
      <Operator type="button" onClick={() => helpers.push(initialValues)}>
        Add Row
      </Operator>
    </div>
  ),
  Remove: ({ id }) => (
    <Operator type="button" onClick={() => helpers.remove(id)}>
      Remove Row
    </Operator>
  ),
});

export default (InternalFields, { title, key }, initialValues) => {
  const FieldRepeater = ({
    id,
    values,
    sortingFn = "index",
    mappingFn,
    ...rest
  }) => {
    const addIndexToDataItem = (xs, index) => {
      const mapped = isFunction(mappingFn) ? mappingFn(xs, index) : xs;

      return {
        ...mapped,
        index,
      };
    };

    const data = sortBy(
      map(get(values, key, []), addIndexToDataItem),
      sortingFn
    );

    return (
      <>
        <h3>{title}</h3>
        <FieldArray
          name={key}
          render={(arrayHelpers) => {
            const { Add, Remove } = makeIncrementorElements(
              arrayHelpers,
              initialValues
            );

            if (size(data) < 1)
              return <Add marginBottom="2rem" marginTop="-1rem" />;

            return map(data, (obj, i) => (
              <Wrap key={obj.id || obj.index} isFirst={isFirst(data, i)}>
                <Stretch>
                  <InternalFields idx={obj.index} id={id} {...rest} />
                </Stretch>
                <Auto>
                  <Remove id={obj.index} />
                  {isLast(data, i) && <Add />}
                </Auto>
              </Wrap>
            ));
          }}
        />
      </>
    );
  };

  FieldRepeater.propTypes = {
    id: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
  };

  return FieldRepeater;
};
