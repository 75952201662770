import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import Group from "../../components/Group";
import withFieldRepeater from "../../components/withFieldRepeater";
import { getFirstNameByUserId } from "../utils";
import withUserDropdown from "../../components/withUserDropdown";
import ExpandedField from "../../components/ExpandedField";
import { ProtectedElement } from "../../components/Protected";

const Commission = ({ idx, workers }) => (
  <ProtectedElement rule="admin">
    <Group>
      <ExpandedField
        label="Worker"
        name={`commissions[${idx}].worker`}
        required
      >
        <option value="">Select</option>
        {map(workers, (obj, i) => (
          <option key={i} value={obj._id}>
            {obj.firstName} {obj.lastName}
          </option>
        ))}
      </ExpandedField>
      <ExpandedField
        label="Rate"
        name={`commissions[${idx}].rate`}
        type="price"
        required
      />
    </Group>
  </ProtectedElement>
);

Commission.propTypes = {
  idx: PropTypes.number.isRequired,
  workers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
};

const El = withFieldRepeater(
  Commission,
  {
    title: "Commissions",
    key: "commissions",
  },
  {
    worker: "",
    rate: "",
  }
);

// eslint-disable-next-line
export default withUserDropdown(
  ({ users, ...props }) => (
    <El
      {...props}
      workers={users}
      sortingFn="name"
      mappingFn={(xs, i) => ({
        id: [xs.worker, i].concat("-"),
        name: getFirstNameByUserId(users, xs.worker),
        ...xs,
      })}
    />
  ),
  {
    contractorsOnly: true,
  }
);
