import gql from "graphql-tag";

export const USER_LIST = gql`
  query getUsers(
    $skip: Float
    $type: String
    $search: String
    $company: ID
    $active: Boolean
  ) {
    getUsers(
      skip: $skip
      type: $type
      search: $search
      company: $company
      active: $active
    ) {
      _id
      firstName
      lastName
      email
      type
    }
  }
`;

export const USER_LIST_DROPDOWN = gql`
  query getUsersForSelect(
    $contractorsOnly: Boolean
    $recruitersOnly: Boolean
    $type: String
    $company: ID
    $manager: ID
    $all: Boolean
  ) {
    getUsersForSelect(
      contractorsOnly: $contractorsOnly
      recruitersOnly: $recruitersOnly
      type: $type
      manager: $manager
      company: $company
      all: $all
    ) {
      _id
      firstName
      lastName
      pos {
        worker
        value
      }
    }
  }
`;

export const USER_BLOCK = gql`
  mutation manageUserAccess($id: ID!, $block: Boolean) {
    manageUserAccess(id: $id, block: $block) {
      _id
      active
      attempt
    }
  }
`;

export const USER_DETAIL_CONTRACT = gql`
  query getUserContract($id: ID!) {
    getUserContract(id: $id) {
      _id
      contract
      manager
      billableRate
      rateType
      originalStartDate
      startDate
      endDate
      role
      hst
      rate
    }
  }
`;

export const USER_DETAIL = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      _id
      firstName
      lastName
      email
      password
      active
      isPortal
      type
      title
      contract
      paymentMadeTo
      business
      hst
      rate
      billableRate
      rateType
      hoursInDay
      tax
      phone
      street
      country
      postal
      city
      province
      manager
      company
      attempt
      url
      po
      pos {
        value
        worker
      }
      commissions {
        showFsNet
        rate
        worker
      }
      invoicingContacts {
        name
        email
        phone
        title
      }
      birthday
      role
      startDate
      originalStartDate
      endDate
      location
      billingCycle
      td1one
      td1e
      gm
      nm
      candidateSource
      registeredAddress {
        street
        city
        province
        postal
        country
      }
      isHst
      isIncorporated
      businessName
      accountManager
    }
  }
`;

export const USER_CREATE = gql`
  mutation createUser(
    $administrator: AdministratorInput
    $manager: ManagerInput
    $moderator: ModeratorInput
    $employee: EmployeeInput
    $subcontractor: SubContractorInput
    $recruiter: RecruiterInput
    $payroll: PayrollInput
  ) {
    createUser(
      administrator: $administrator
      manager: $manager
      moderator: $moderator
      employee: $employee
      subcontractor: $subcontractor
      recruiter: $recruiter
      payroll: $payroll
    ) {
      insertedId
    }
  }
`;

export const USER_UPDATE = gql`
  mutation updateUser(
    $id: ID!
    $administrator: AdministratorInput
    $manager: ManagerInput
    $moderator: ModeratorInput
    $employee: EmployeeInput
    $subcontractor: SubContractorInput
    $recruiter: RecruiterInput
    $payroll: PayrollInput
  ) {
    updateUser(
      id: $id
      administrator: $administrator
      moderator: $moderator
      manager: $manager
      employee: $employee
      subcontractor: $subcontractor
      recruiter: $recruiter
      payroll: $payroll
    ) {
      _id
      firstName
      lastName
      email
      password
      active
      isPortal
      type
      title
      contract
      paymentMadeTo
      accountManager
      business
      hst
      rate
      billableRate
      rateType
      hoursInDay
      tax
      phone
      street
      postal
      country
      city
      province
      manager
      company
      po
      pos {
        worker
        value
      }
      commissions {
        worker
        rate
        showFsNet
      }
      invoicingContacts {
        name
        email
        phone
        title
      }
      birthday
      role
      startDate
      originalStartDate
      endDate
      location
      billingCycle
      td1one
      td1e
      gm
      nm
      candidateSource
      businessName
      registeredAddress {
        street
        city
        province
        postal
        country
      }
      isHst
      isIncorporated
    }
  }
`;

export const USER_DELETE = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      active
    }
  }
`;

export const USER_EXPORT_GENERATION = gql`
  mutation generateUserExportReport {
    generateUserExportReport {
      data
    }
  }
`;

export const USER_INACTIVE_EXPORT_GENERATION = gql`
  mutation generateUserInactiveExportReport {
    generateUserInactiveExportReport {
      data
    }
  }
`;

export const RECRUITER_EXPORT_GENERATION = gql`
  mutation generateRecruiterCommissionsReport {
    generateRecruiterCommissionsReport {
      data
    }
  }
`;

export const CONTRACTOR_LIST_GENERATION = gql`
  mutation genContractorList {
    genContractorList {
      data
    }
  }
`;
